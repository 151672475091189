//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns'
import { mapState } from 'vuex'
import api from './networking/api'
import common from './common/common'
import { LocalStorage } from 'quasar'
export default {
    name: "PlacesList",
    data() {
        return {
            map: null,
            mapPosition: {latitude: '', longitude: ''},
            isAddMarker: false,
            address: '',
            city: '',
            addressList: [],
            shopName: '',
            fullscreenLoading: false,
            activeName: 'A',
            dialogFormVisible: true,
            storeListLoading: false,
            storesOptions: [],
            stores: [],
            curStore: '',
            storeID: '',
            storeName: '',
            storeBrand: '',
            wxConfigData: null,
            placesList: [],
            descMap: {},
            items: {},
            temporaryDom: null,
            monthUploadNum: 0,
            allUploadNum: 0
        }
    },
    filters: {
        formatDate(value) {
            let date = new Date(value);
            return format(date, 'yyyy-MM-dd');
        },
        convertBrand: function(value) {
            return common.formatBrand(value);
        }
    },
    methods: {
        searchAddress() {
            if (this.address.length == 0) {
                alert("搜索地址不能为空");
                return;
            }
            this.requestSearchMapAddress(this.address);
            // alert('address:' + this.address);
        },
        requestSearchMapAddress(text) {
            let that = this;
            //let boundary = `nearby(${this.mapPosition.latitude},${this.mapPosition.longitude},1000)`;
            let boundary = `region(${this.city})`;
                this.axios.post(api.mapSearch, {key: common.config.mapKey, keyword: text, boundary: boundary})
                .then(function (response) {
                    if (common.success(response)) {
                        let data = response.data.data;
                        if (data.status != 0) {
                            alert(data.message);
                            return;
                        }
                        that.addressList = data.data;
                        let addressListEl = document.getElementById('address-list');
                        addressListEl.setAttribute('class', 'address-list');
                    } else {
                        alert('搜索出错：' + response.data.msg);
                    }
                    // alert(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                    alert('搜索出错：' + error);
                })
                .then(function () {

                });
        },
        selectAddress(item) {
            console.log(item);
            this.hiddenMap();
            let location = item.location;
            this.requestUpdateLongLatitude(location.lat, location.lng, this.curStore.storeID);
            //this.requestPlaceList(this.curStore.storeID);
        },
        hiddenMap() {
            if (this.map) {
                this.map.destroy();
            }
            this.map = null;
            this.isAddMarker = false;
            let mapEl = document.getElementById('map');
            if (mapEl) {
                mapEl.setAttribute('class', '');
                mapEl.setAttribute('style', '');
            }

            let mapSearch = document.getElementById('map-search');
            if (mapSearch) {
                mapSearch.classList.add("map-search", "hidden");
            }

            let addressListEl = document.getElementById('address-list');
            if (addressListEl) {
                addressListEl.classList.add("address-list", "hidden");
            }

        },
        selectPosition() {
            if (!this.mapPosition.latitude || !this.mapPosition.longitude) {
                alert('经纬度不能为空')
                return;
            }
            this.requestUpdateLongLatitude(this.mapPosition.latitude, this.mapPosition.longitude, this.curStore.storeID);
        },
        filterFn(val, update, abort) {
            update(() => {
                const needle = val.toUpperCase();
                this.storesOptions = this.stores.filter(function(v) {
                    console.log(v);
                    let result = v.name.toUpperCase().indexOf(needle) > -1;
                    let result1 = v.shopName.indexOf(needle) > -1;
                    if (result || result1) {
                        return true;
                    }
                    return false;
                })
            })
        },
        goDetail(item) {
            console.log('goDetail',item)
            let desc = '';
            if (item.places.length > 0) {
                desc = this.descMap[item.places[0]['groupName']] || '';
            }
            let params = { wxConfigData: this.wxConfigData, curStore: this.curStore, places: item.places, desc: desc}
            LocalStorage.set('params', JSON.stringify(params));
            this.$router.push({path:'/storemanagement/placesdetail'});

             // this.$router.push({ name: 'StoreManagement', params: { wxConfigData: this.wxConfigData, curStore: this.curStore, places: item.places}});
        },
        tabClick(item) {
            // console.log(item);
        },
        showMap() {
            // alert('showMap');
            let that = this;
            // that.mapPosition.latitude = '40.02906301748584';
            // that.mapPosition.longitude = '116.25499991104516';
            // that.initMap('40.02906301748584', '116.25499991104516');
            // return;

            if (!this.mapPosition.latitude) {
                wx.getLocation({
                    type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        that.mapPosition.latitude = res.latitude;
                        that.mapPosition.longitude = res.longitude;
                        that.initMap(res.latitude, res.longitude)
                        console.log(res)
                    },
                    fail: function (err) {
                        console.log(err);
                        alert('请授权位置权限');
                        // that.mapPosition.latitude = '40.02906301748584';
                        // that.mapPosition.longitude = '116.25499991104516';
                        // that.initMap('40.02906301748584', '116.25499991104516')
                    }
                });
            } else {
                this.initMap(this.mapPosition.latitude, this.mapPosition.longitude);
            }
        },
        requestSignature: function (code) {
            this.storeListLoading = true;
            let that = this;
            this.axios.post(api.login, {
                appName: "qywx_app15",
                code: code,
                source: "qywx"
            })
                .then(function (response) {
                    console.log(response);
                    //alert(JSON.stringify(response.data));
                    if (common.success(response)) {
                        let data = response.data.data;
                        let accessToken = data.access_token;
                        localStorage.setItem('accessToken', accessToken);
                        that.requestJsSdkConfig();
                        if (!data.ownerShops) {
                            alert("门店权限未配置");
                            return
                        }

                        let ownerShops = data.ownerShops.split(',');
                        let ownerShopNames = data.ownerShopNames.split(',');

                        that.stores = [];
                        for (let i = 0; i < ownerShops.length; i++) {
                            that.stores.push({id: '', brand:'', shopName: ownerShopNames[i], name: ownerShops[i] + '_', storeID: ownerShops[i]});
                        }
                        if(that.stores.length == 1){
                            that.curStore = that.stores[0]
                            that.handleSelectStore()
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert('登陆出错:' + error);
                    //that.storeListLoading = false;
                })
                .then(function () {
                    //   that.fullscreenLoading = false;
                    that.storeListLoading = false;
                });
        },
        requestPlaceList: function (id) {
            this.hiddenMap();
            this.fullscreenLoading = true;
            this.items = [];
            let that = this;

            this.axios.get(`${api.placeList}?storeid=${id}&brand=${this.curStore.brand}`)
                .then(function (response) {
                    // alert(JSON.stringify(response.data));
                    console.log(response.data.data);
                    if (common.success(response)) {
                        that.descMap = response.data.data.descMap;
                        that.allUploadNum = response.data.data.allUploadNum;
                        that.monthUploadNum = response.data.data.monthUploadNum;
                        let items = response.data.data.list;
                        items.sort(function (a, b) {
                            return Number(a.sort) - Number(b.sort);
                        })
                        that.placesList = items;
                        let groupDict = {};
                        let imagePlaces = {};
                        let existImages = {}
                        for (var i = 0; i < items.length; i++) {
                            let item = items[i];
                            let groupName = item.groupName;
                            if (!groupName) {
                                console.log('groupName is null')
                                continue;
                            }
                            let arr = groupName.split('_');
                            if (arr.length < 2) {
                                continue;
                            }
                            let type = arr[0];
                            let name = arr[1];
                            let groupArr = groupDict[type];
                            if (!groupArr) {
                                groupArr = [];
                            }
                            let existArr = existImages[item.groupId];
                            if (!existArr) {
                                existArr = [];
                            }
                            let imageArr = imagePlaces[item.groupId];
                            if (!imageArr) {
                                imageArr = [];
                            }
                            var image = '';
                            var imageCode = '';
                            // console.log(item.data);
                            if (item.data.length > 0) {
                                 image = api.imageHost + "/" + item.data[0].image;
                                 imageCode = item.data[0].id;
                                 existArr.push(item.data[0].updatedAt.replace(/-/g, '/'));
                            }
                            imageArr.push({image: image, name: item.name, code: item.code, imageCode: imageCode, groupName: item.groupName,date:(item.data[0])?item.data[0].updatedAt:''});
                            let dict = {groupId: item.groupId, name: name};
                            let find = groupArr.some(item => item.name == name);
                            if (!find) {
                                groupArr.push(dict);
                            }
                            groupDict[type] = groupArr;
                            imagePlaces[item.groupId] = imageArr;
                            if (existArr.length > 0) {
                                existArr.sort(function (a, b) {
                                    return new Date(b).getTime()-new Date(a).getTime();
                                })
                            }
                            existImages[item.groupId] = existArr;
                        }
                        let result = [];

                        for (let key in groupDict) {
                            let arr = groupDict[key];
                            for (let item of arr) {
                                let places = imagePlaces[item.groupId];
                                item.places = places;
                                item.exists = existImages[item.groupId];
                            }
                            result.push({label: key + '组', name: key, data: arr });
                        }
                        result.sort(function (a, b) {
                            return a.name.localeCompare(b.name);
                        })
                        that.items = result;//groupDict;
                        // console.log(groupDict);
                    } else {
                        alert('获取门店位置出错：' + response.data.reason);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert(error);
                    that.fullscreenLoading = false;
                })
                .then(function () {
                    that.fullscreenLoading = false;
                });
        },
        requestStoreInfo: function(storeID, brand) {
            let that = this;
            this.axios.get(api.getStoreInfo + '?storeid=' + storeID + '&brand=' + brand)
                .then(function (response) {
                    console.log(response);
                    if (common.success(response)) {
                        let data = response.data.data;
                        that.address = data.address;
                        that.city = data.city;
                        // if (!data['longitudeGd'] || !data['latitudeGd']) {
                        //     wx.getLocation({
                        //         type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        //         success: function (res) {
                        //             that.mapPosition.latitude = res.latitude;
                        //             that.mapPosition.longitude = res.longitude;
                        //             that.initMap(res.latitude, res.longitude)
                        //             console.log(res)
                        //         },
                        //         fail: function (err) {
                        //             console.log(err)
                        //             //alert('请授权位置权限')
                        //             that.mapPosition.latitude = '40.02906301748584';
                        //             that.mapPosition.longitude = '116.25499991104516';
                        //             that.initMap('40.02906301748584', '116.25499991104516')
                        //         }
                        //     });

                        // } else {
                            that.requestPlaceList(that.curStore.storeID)
                        // }

                    } else {
                         alert('获取门店详情信息失败');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert(error)
                })
                .then(function () {

                });
        },
        requestUpdateLongLatitude : function(latitude, longitude, storeId) {
            this.fullscreenLoading = true;
            let that = this;
            this.axios.post(api.updateLongLatitude, {latitude, longitude, storeId})
                .then(function (response) {
                    if (common.success(response)) {
                        that.requestPlaceList(that.curStore.storeID)
                    } else {
                        alert('更新经纬度失败：', response.data.msg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert('更新经纬度失败：', + error);
                })
                .then(function () {
                    that.fullscreenLoading = false;
                });
        },
        handleSelectStore: function() {
            if (!this.curStore || this.curStore.length == 0) {
                this.$dialog.alert({
                    message: '请先选择门店',
                });
                return;
            }

            console.log(this.curStore)
            this.storeName = this.curStore.name;
            this.shopName = this.curStore.shopName;
            this.storeID = this.curStore.storeID;
            this.storeBrand = this.curStore.brand;
            this.dialogFormVisible = false;
            this.activeName  = 'A';
            this.requestStoreInfo(this.storeID, this.storeBrand);
            // this.requestPlaceList(this.curStore.id);

        },
        requestJsSdkConfig: function() {
            let that = this;
            // let agentId = common.config.agentId;
            // let corpId = common.config.corpId;
            let agentId = LocalStorage.getItem("agentId");
            let corpId = LocalStorage.getItem("corpId");
            this.axios.get(api.getJsSdkConfig, {
                params: {
                    agentId,
                    corpId,
                    url: encodeURIComponent(window.location.href.split('#'))
                }
            })
                .then(function (response) {
                    console.log(response.data.data);
                    if (common.success(response)) {
                        let data = response.data.data;
                        that.wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                        that.wxConfig(that.wxConfigData)
                        that.requestUserInfo();
                    } else {
                        alert('获取JsSdkConfig失败：' + response.data.reason);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert('获取JsSdkConfig失败：' + error);
                })
                .then(function () {

                });
        },
        getQueryString: function(name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        wxConfig: function(data) {
            // alert(JSON.stringify(data))
            wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，企业微信的corpID
                timestamp: data.timestamp, //  必填，生成签名的时间戳
                nonceStr: data.nonceStr, //  必填，生成签名的随机串
                signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: ['getLocation', 'chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });

            wx.ready(function () {
                console.log('reday');
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            });

            wx.error(function (res) {
                console.log(res);
                alert(JSON.stringify(res));
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
        },
        initMap: function(latitude, longitude) {
            this.items = [];
            //定义地图中心点坐标
            var center = new TMap.LatLng(latitude, longitude)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(document.getElementById('map'), {
                center: center,//设置地图中心点坐标
                zoom: 17.2,   //设置地图缩放级别
                pitch: 43.5,  //设置俯仰角
                rotation: 45,    //设置地图旋转角度
                showControl: false
            });

            //初始化marker图层
            var markerLayer = new TMap.MultiMarker({
                id: 'marker-layer',
                map: this.map
            });

            //初始化infoWindow
            var infoWindow = new TMap.InfoWindow({
                map: this.map,
                position: new TMap.LatLng(latitude, longitude),
                offset: { x: 0, y: -42 } //设置信息窗相对position偏移像素
            });
            // infoWindow.close();//初始关闭信息窗关闭

            let html = `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 5px;"><div><span>当前位置</span></div><div style="width: 120px; height: 44px; margin-top: 5px; border: 1px solid #cdcdcd; display: flex; justify-content: center; align-items: center; cursor: pointer;" onclick="selectPosition()">确定位置</div></div>`
            infoWindow.setContent(html);

            if (!this.isAddMarker) {
                markerLayer.add({
                    id: "1",
                    position: {lat: latitude,lng:longitude }
                });
                this.isAddMarker = true;
            }

            //监听点击事件添加marker
            this.map.on("click", (evt) => {
                console.log(evt)
                if (this.isAddMarker) {
                    markerLayer.updateGeometries([
                        {
                            id: "1",
                            position: evt.latLng,
                        }
                    ])
                } else {
                    markerLayer.add({
                        id: "1",
                        position: evt.latLng
                    });
                    this.isAddMarker = true
                }

                this.mapPosition.latitude = evt.latLng.lat;
                this.mapPosition.longitude = evt.latLng.lng;
                let poi = evt.poi;
                let html = `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 5px;"><div><span>${poi ? poi.name : '未知' }</span></div><div style="width: 120px; height: 44px; margin-top: 5px; border: 1px solid #cdcdcd; display: flex; justify-content: center; align-items: center; cursor: pointer;" onclick="selectPosition()">确定位置</div></div>`
                infoWindow.setContent(html);//设置信息窗内容
                infoWindow.open(); //打开信息窗
                infoWindow.setPosition(evt.latLng);//设置信息窗位置

            });

            let mapEl = document.getElementById('map');
            mapEl.setAttribute('class', 'map');
            mapEl.setAttribute('style', '');

            let mapSearchEl = document.getElementById('map-search');
            mapSearchEl.setAttribute('class', 'map-search');

            // markerLayer.on("click", function (evt) {
            //     alert(JSON.stringify(evt));
            //     //设置infoWindow
            //
            // })
        },
        requestUserInfo: function () {
            let that = this;
            this.fullscreenLoading = true;
            this.axios.get(api.getUserInfo)
                .then(function (response) {
                    console.log(response.data.data);
                    if (common.success(response)) {
                        let data = response.data.data;
                        let stores = data.attr.saleStore;
                        that.stores = [];
                        for (let i = 0; i < stores.length; i++) {
                            let store  = stores[i];
                            that.stores.push({id: '', brand:store.brand, shopName: store.title, name: `${store.storeId}_${common.formatBrand(store.brand)}`, storeID: store.storeId});
                        }
                    } else {
                        alert('获取getUserInfo失败：' + response.data.reason);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    alert('获取getUserInfo失败：' + error);
                })
                .then(function () {
                    that.fullscreenLoading = false;

                });
        }
},
    computed: mapState([
        'needRefreshList'
    ]),
    watch: {
        needRefreshList: function (value, oldValue) {
           if (value > 0 && this.curStore) {
               this.requestPlaceList(this.curStore.storeID);
           }
        }
    },
    mounted() {
        /*
        let ownerShops = LocalStorage.getItem('ownerShops');
        let ownerShopNames = LocalStorage.getItem('ownerShopNames');

        if (ownerShops == 'null' || !ownerShops) {
            alert("门店权限未配置");
            return
        }

        ownerShops = ownerShops.split(',');
        ownerShopNames = ownerShopNames.split(',');

        this.stores = [];
        for (let i = 0; i < ownerShops.length; i++) {
            this.stores.push({id: '', brand:'', shopName: ownerShopNames[i], name: ownerShops[i] + '_', storeID: ownerShops[i]});
        }

        console.log(ownerShops, ownerShopNames);
        */
        this.requestJsSdkConfig();
        //let code = this.getQueryString('code');
        // this.requestSignature(code);
        // alert(TMap);
        window.selectPosition = this.selectPosition;
        document.title = "陈列照片";
    }
}
